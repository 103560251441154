import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-news.png'
import NewsList from '@/components/modules/News/List'
import Pagination from '@/components/common/Pagination'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import RecruitLead from '@/components/modules/Recruit/Lead'

const NewsListPage = props => {
  const posts = props.data.allMarkdownRemark.edges.map(edge => {
    const postDate = new Date(edge.node.frontmatter.date)
    return {
      ...edge.node.frontmatter,
      date: {
        fullYear: `${postDate.getFullYear()}`,
        year: `${postDate.getFullYear()}`.substring(2, 4),
        month: `${postDate.getMonth() + 1}`,
        date: `${postDate.getDate()}`
      },
      html: edge.node.html
    }
  })
  return (
    <Root>
      <SEO location={props.location} title="新着情報" description="株式会社ウィザードに関する最新情報です。"/>
      <Head>
        <PageTitle src={imgTitle}>新着情報</PageTitle>
      </Head>
      <Main>
        <Row>
          <NewsList posts={posts} />
          <Pagination {...props.pageContext} path="/news/" css={styles.Pagination} />
        </Row>
        <Row css={styles.Breadcrumb}><Breadcrumb path="/news/"/></Row>
        <Row css={styles.Contact}><ContactLead /></Row>
        <Row css={styles.Recruit}><RecruitLead /></Row>
      </Main>
    </Root>
  )
}

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const styles = {
  Pagination: css`
    margin-top: 64px;
  `,
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Recruit: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(NewsListPage)

export const newsListQuery = graphql`
  query newsListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { postType: { eq: "news" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            postType
            title
            date
          }
        }
      }
    }
  }
`
