import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { getThemeColor } from '@/utils/themes'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import DefList from '@/components/common/DefList'
import DefListItem from '@/components/common/DefListItem'
import DefListHead from '@/components/common/DefListHead'
import DefListDesc from '@/components/common/DefListDesc'
import ParticleBackground from '@/components/common/ParticleBackground'
import Typography from '@/components/common/Typography'

const NewsLead = props => (
  <Root theme={props.theme}>
    <Background scale={400} />
    <Main>
      <List>
        {props.posts.map((post, index) => (
          <Item key={index}>
            <Item_Head>
              <Item_Date
                variant="h4"
                component="time"
                lang="en"
                dateTime={`${post.date.fullYear}-${post.date.month}-${post.date.date}`}>
                {`${post.date.year}.${('00' + post.date.month).slice(-2)}.${('00' + post.date.date).slice(-2)}`}
              </Item_Date>
            </Item_Head>
            <Item_Desc>
              <Item_Title variant="h4">{post.title}</Item_Title>
              <Item_Content variant="body2" component="div" dangerouslySetInnerHTML={{ __html: post.html }} theme={props.theme} />
            </Item_Desc>
          </Item>
        ))}
      </List>
    </Main>
  </Root>
)

export default ThemeConsumer(NewsLead)

const Root = styled.div`
  position: relative;
  @media ${mq.and(desktop, laptop, tabletL)} {
    padding: 80px;
  }
  @media ${mq.and(tabletS)} {
    padding: 40px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 40px 16px;
  }
`

const Background = styled(ParticleBackground)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Main = styled.div``

const List = styled(DefList)``

const Item = styled(DefListItem)``

const Item_Head = styled(DefListHead)`
  width: 200px;
  @media ${mq.and(mobile)} {
    width: auto;
  }
`

const Item_Desc = styled(DefListDesc)``

const Item_Date = styled(Typography)``

const Item_Title = styled(Typography)``

const Item_Content = styled(Typography)`
  margin-top: 12px;
  a {
    font-weight: bold;
    color: ${props => getThemeColor('primary.light', props.theme)};
    text-decoration: underline;
  }
`
